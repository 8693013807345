.Track-Container {
    display: flex;
    float: none;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0.25em auto;
    border: solid black;
    border-width: 1px 3px 3px 1px;
    border-radius: 8px;
    background-color: #c6c6c6;
    height: 70px;
    width: 100%;
    min-width: 245px;
}

.Track-AlbumCover {
    margin: auto 15px;
    height: 50px;
}

.Track-Name {
    display: block;
    margin-bottom: 5px;
    text-align: left;
    font-size: 0.75rem;
    font-weight: bold;
}

.Track-AlbumName {
    text-align: left;
    font-size: 0.65rem;
}

.Track-Info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.highlighted {
    border-radius: 5px;
    background-color: #434242;
    padding: 3px 7px;
    color: #f5f5ff;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
}
.responsive-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media screen and (min-width: 0) {
    .responsive-text {
        max-width: 110px;
    }
}
@media screen and (min-width: 420px) {
    .responsive-text {
        max-width: 180px;
    }
}
@media screen and (min-width: 540px) {
    .responsive-text {
        max-width: 280px;
    }
}
@media screen and (min-width: 768px) {
    .responsive-text {
        max-width: 350px;
    }
}

.Track-Choice {
    display: flex;
    flex: 1;
    align-self: center;
    justify-content: flex-end;
    margin-right: 1rem;
}

.Track-Checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.Track-Checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

.Track-Checkbox + label::before {
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0.5em;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    width: 1.5em;
    height: 1.5em;
    content: "";
}

.Track-Checkbox:checked + label::before {
    border-color: #434242;
    background-color: #434242;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.Track-Checkbox:not(:disabled):not(:checked) + label:hover::before {
    border-color: #c4c3c3;
}

.Track-Checkbox:not(:disabled):active + label::before {
    border-color: #c4c3c3;
    background-color: #c4c3c3;
}

.Track-Checkbox:focus + label::before {
    box-shadow: 0 0 0 0.2rem #808080;
}

.Track-Checkbox:focus:not(:checked) + label::before {
    border-color: #8f8e8e;
}

.Track-Checkbox:disabled + label::before {
    background-color: #c4c3c3;
}
